@import '../../styles/index.scss';

.UserContainer {
  width: 100%;
  background-color: $grayColor5;
  @include border(solid, 0px, 5px, transparent);
  @include flex(row, space-between, center, nowrap);
  padding: 0px 10px;
  margin-bottom: 5px;
  max-height: 12vh;
  height: 12vh;
  
  .UserBodySummary {
    @include flex(row, space-between, center, nowrap);
    width: 70%;
  }

  .UserBodyContainer {    
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    padding: 0 10px;
    div {
      max-width: 200px;
      min-width: 100px;
    }
  }

  .UserChartContainer {
    width: 10vw;
    height: 40px;
  }

  .UserAvatarContainer {
    @include flex(row, flex-start, center, nowrap);
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    span{
      color: $fontColor1;
      font-size: 1.25rem;
      font-weight: 700;
    }
    div {
      margin-left: 5px;
      @include flex(row, center, flex-start, nowrap);
    }

    .UserAvatarContainerRenderName {
      @include flex(column, center, flex-start, nowrap);
    }
  }
}

.UserAvatarContainerRenderName {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    span {
      color: $actionColor1;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .UserBodyContainer {    
    @include flex(row, flex-end, center, nowrap);
    width: 100% !important;
  }

  .UserChartContainer {
    display: none;
  }
}
