// colors
$fontColor1: #ffffff;
$fontColor2: #cccccc;
$actionColor1: #15E7BC;
$actionColor2: #FF46B5;
$actionColor3: #56CCF2;
$actionColor4: #FF5959;
$grayColor1: #EDEDEF;
$grayColor2: #D2D3D6;
$grayColor3: #B1B3B8;
$grayColor4: #696C76;
$grayColor5: #2C3436;
$grayColor6: #242A2B;
$grayColor7: #151E1F;
$grayColor8: #030303;

// steps
$step1: 10px;
$step2: 20px;
$step3: 30px;

// z-index
$zIndexLevel1K: 1000;
$zIndexLevel999: 999;
$zIndexLevel998: 998;

// amplify
:root {
  --amplify-primary-color: #15E7BC;
  --amplify-secondary-color: white;
  --amplify-primary-tint: #12b897;
  --amplify-primary-shade: #56CCF2;
  --amplify-grey: #696C76;
  --amplify-light-grey: #696C76;
  --amplify-white: #151E1F;
  --amplify-red: red;
  --amplify-tertiary-shade:	#FF46B5;
  --border-style: 5px;
}