@import '../../styles/index.scss';

.PackContainer {
  padding: 10px;
  position: relative;
 
  .PackImgContainer {
    width: 100%;
    height: 35vh;
    max-height: 300px;
    max-width: 300px;
    margin: 0 auto;
    // height: 75%;
    border-radius: 5px;
    // margin-bottom: 10px;
    @include backgroundImg(cover)
  }
  .PackCheckbox {
    position: absolute;
    top: 5px;
    left: 5px;
    color: $actionColor1;
  }
}
.PackBodyContainer {
  @include flex(column, space-between, center, nowrap);
  height: 25%;
  padding: 10px 0px;
  color: $fontColor1;
  span {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  div {
    padding: 5px 10px;
    background-color: $grayColor7;
    border-radius: 5px;
    span {
      font-size: 1rem;
      color: $grayColor3;
    }
  }
}
